import React from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import LanguageSelector from '../LanguageSelector/LanguageSelector';
import { Container, Grid } from '@material-ui/core';
import { AppState } from '../../store';
import { connect } from 'react-redux';
import { Culture } from '../../store/cultures/types';
import { cultureChange, CultureChange } from '../../store/cultures/actions';
import { Dispatch } from 'redux';
import { useAuthentication } from '../../Providers/AuthenticationProvider';
import { useStyles } from './Styles';
import { urlJoin } from 'url-join-ts';
import settings from '../../config/settings';
import UserMenuSelector from '../UserMenuSelector/UserMenuSelector';
import { useTranslation } from 'react-i18next';
import './styles.scss';

const Header: React.FC<Props> = ({ cultures, changeCulture, profile }) => {
    const classes = useStyles();
    const { webkitActive } = useAuthentication();
    const { t } = useTranslation();

    const setCulture = (culture: Culture) => {
        const selectedCultureId = culture.id;
        changeCulture(selectedCultureId);

        sessionStorage.setItem('selectedCulture', selectedCultureId);
    }
    
    return (
        <React.Fragment>
            <CssBaseline />
            <AppBar position="static" elevation={0} className={classes.appBar} id="DIPHeader">
                <Container className={`myAccount`} >
                    <Grid item xs={12} className={classes.headerMargin}>
                        <Toolbar className={classes.toolbar} disableGutters={true}>
                            <div className={classes.leftContainer}>
                                <div className={classes.leftSubContainer}>
                                    <Link to="/" className={`${classes.headerItemContainer} triggerWarning`}>
                                        <img src={urlJoin(settings.staticFiles.endpoint, '/images/system/logo-eng-tomorrow.png')} className={classes.logo} alt="Danfoss logo" />
                                    </Link>
                                </div>
                            </div>
                            <div className={classes.rightContainer}>
                                <div className={classes.rightSubContainer}>
                                    {profile && (
                                        <Link to="/" className={`${classes.headerItemContainer} triggerWarning`}>
                                            <img src={urlJoin(settings.staticFiles.endpoint,'/images/system/Header_Apps.svg')} className={`${classes.headerIcon} triggerWarning`} alt="" />
                                            <span translate="no" className={`${classes.headerSpacer} ${classes.appsBigScreen}`}>{t('header.other-apps')}</span>
                                            <span translate="no" className={`${classes.headerSpacer} ${classes.appsSmallScreen}`}>{t('header.other-apps-mobile')}</span>
                                        </Link>
                                    )}
                                    <div id="languageSelectorContainer" className={`${cultures.cultures.filter(c => c.has_been_translated).length === 1 ? classes.hideLanguageSelector : ''} triggerWarning`}>
                                        <LanguageSelector cultures={cultures} onSelect={setCulture} />
                                    </div>
                                    {!webkitActive ?
                                        <div className="triggerWarning">
                                            <UserMenuSelector />
                                        </div>
                                        : null}
                                </div>
                            </div>
                        </Toolbar>
                    </Grid>
                </Container>
            </AppBar>
        </React.Fragment>
    );
}

const mapStateToProps = (state: AppState) => ({
    cultures: state.cultures,
    profile: state.profile
});

const mapDispatchToProps = (dispatch: Dispatch<CultureChange>) => ({
    changeCulture: (newCulture: string) => dispatch(cultureChange(newCulture))
});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(Header);
