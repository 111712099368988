import React, { useState, useEffect, useContext } from "react";
import {Auth0Client} from "@auth0/auth0-spa-js";
import { useSpinner } from "./SpinnerProvider";
import queryString from 'query-string';
import settings from '../config/settings';
import DOMPurify from 'dompurify';

const DEFAULT_REDIRECT_CALLBACK = () => { return; };

const DEFAULT_REDIRECT_PERSIST_STATE = () => { return; };
const DEFAULT_ONAUTHENTICATED = (accessToken, user) => { return; };
const DEFAULT_ONREFRESHERROR = (error) => { return; };

export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);
export let auth0Client;

const doSSOLogin = async (authClient, isAuth, automaticSso, requireSignin, setError, onRedirectPersistState) => {
  if (!isAuth && automaticSso) {
    try {
      // Try if sso is possible
      await authClient.getTokenSilently();
      isAuth = await authClient.isAuthenticated();
    }
    catch (err) {
      if (err.error !== 'login_required') {
        setError(err);
      }
    }
  }

  if (!isAuth && requireSignin) {
    try {
      // Try if sso is possible
      await authClient.loginWithRedirect({ appState: onRedirectPersistState() });
    }
    catch (err) {
      setError(err);
    }
  }
}

const handleError = async (err, queryParams, onRedirectCallback, authClient, setError) => {
  // Handling issue 'Invalid State Error on Ipad safari and chrome #515'
  // https://github.com/auth0/auth0-spa-js/issues/515
  if (err.toString() === 'Error: Invalid state') {
    // Get error and error_description from query params, because they show the real error returned from Auth0
    // while the err object contains the invalid state error
    const realError = DOMPurify.sanitize(queryParams.error);
    const realErrorDescription = DOMPurify.sanitize(queryParams.error_description);
    if (!realError) {
      // No error returned, so it must be a succesfull login with invalid state
      // Try a getTokenSilently to finalize the login and getting a token
      onRedirectCallback();
      await authClient.getTokenSilently();
    }
    else {
      // Use the real error from query string in setError
      onRedirectCallback();
      setError({...err, error: realError, error_description: realErrorDescription});
        
    }  
  }
  else {
    onRedirectCallback();
    setError(err);
  }
}

export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  onRedirectPersistState = DEFAULT_REDIRECT_PERSIST_STATE,
  onAuthenticated = DEFAULT_ONAUTHENTICATED,
  onRefreshError = DEFAULT_ONREFRESHERROR,
  logout_uri,
  automaticSso,
  requireSignin,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [initialized, setInitialized] = useState(false);

  const { setSpinnerVisibility } = useSpinner();

  const uniformErrorCodes = (auth0Error) => {
    if (auth0Error.error === 'access_denied' && auth0Error.error_description === 'Interaction required') {
      return {...auth0Error, error: 'interaction_required', error_description: 'External interaction required'}
    }
    if (auth0Error.error === 'invalid_grant' && auth0Error.error_description === 'Unknown or invalid refresh token.') {
      return {...auth0Error, error: 'login_required'}
    }
    return auth0Error;
  }

  useEffect(() => {
    const initAuth0 = async () => {
      // Initialize Auth0Client
      setSpinnerVisibility(true);
      
      auth0Client = new Auth0Client(initOptions);
      const queryParams = queryString.parse(window.location.search);
      if (DOMPurify.sanitize(queryParams.code || queryParams.error) && window.location.pathname.toLowerCase() === '/login' ) {
        try {
          const { appState } = await auth0Client.handleRedirectCallback();
          onRedirectCallback(appState);
        }
        catch (err) {
          handleError(err, queryParams, onRedirectCallback, auth0Client, setError)
        }
      }

      let isAuth = await auth0Client.isAuthenticated();

      doSSOLogin(auth0Client, isAuth, automaticSso, requireSignin, setError, onRedirectPersistState)

      setIsAuthenticated(isAuth);

      if (isAuth) {
        const usr = await auth0Client.getUser();
        const accessToken = await auth0Client.getTokenSilently();
        auth0Client.getTokenSilently({audience: settings.myDanfossAPI.audience, scope:'openid profile email app.read', analytics: false})
        setUser(usr);
        onAuthenticated(accessToken, usr);
      }

      if (isAuth || !requireSignin) {
        setLoading(false);
      }
      setInitialized(true);
      setSpinnerVisibility(false);
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const usr = await auth0Client.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(usr);
  };

  const handleAutomaticSsoSilent = async (p) => {
    if (initialized) {
      if (!isAuthenticated) {
        try {
            setLoading(true);
            const accessToken = await auth0Client.getTokenSilently(p);
            const isAuth = await auth0Client.isAuthenticated();
            if (isAuth) {
              const usr = auth0Client.getUser();
              onAuthenticated(accessToken, usr);
              setIsAuthenticated(isAuth);
            }
            
            return accessToken;
        } 
        catch (err) {
          setIsAuthenticated(false);
          throw uniformErrorCodes(err);
        }
        finally {
          setLoading(false);
        }
      }
    }
  }

  const handleGetTokenSilently = async (p) => {
    try {
        return await auth0Client.getTokenSilently(p);
    } 
    catch (err) {
      onRefreshError(uniformErrorCodes(err));
      setIsAuthenticated(false);
      throw uniformErrorCodes(err);
    }
  }

  return (
    <Auth0Context.Provider
      value={{
        initialized,
        isAuthenticated,
        user,
        loading,
        error,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (p) => auth0Client.loginWithRedirect({ appState: onRedirectPersistState(), ...p} ) ,
        getTokenSilently: (...p) => handleGetTokenSilently(...p),
        automaticSso: (p) => auth0Client.loginWithRedirect({ appState: onRedirectPersistState(), ...p, prompt: 'none'} ),
        automaticSsoSilent: async (...p) => handleAutomaticSsoSilent(...p),
        logout: (...p) => auth0Client.logout(...p),
        logoutWithRedirect: (p) => auth0Client.logout({ returnTo: logout_uri, ...p })
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
